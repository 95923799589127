import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';
import Link from '../../components/link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8472: ImageType,
    _DSF8476: ImageType,
    _DSF8489: ImageType,
    _DSF8493: ImageType,
    _DSF8504: ImageType,
    _DSF8506: ImageType,
    _DSF8508: ImageType,
    _DSF8519: ImageType,
    _DSF8521_panorama: ImageType,
    _DSF8529_panorama: ImageType,
    _DSF8533_panorama: ImageType,
  },
};

class Blog_2018_09_21_Engelstead extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="4BIV" title="Engelstead" />
        <p>
          Day 2 of the Zion Canyon Rendezvous we did Engelstead. Keith from{' '}
          <Link to="https://outnback.com/">Out N Back</Link> was generous and
          dropped us off at the top and offered to bring the 300'er back so we
          didn't have to lug it with us!
        </p>
        <p>
          Our group consisted of myself, Corey, Trent, Byron, Dave, and David.
          The two Daves were new to Engelstead and this was David's first >200'{' '}
          <GlossaryLink>rappel</GlossaryLink>.
        </p>
        <Image image={data._DSF8472} caption="Trent at the 300' drop in" />
        <Image image={data._DSF8476} caption="Corey on the big rappel" />
        <p>
          There was a group behind us and we had 6 people so we{' '}
          <GlossaryLink id="sequence-rappels">sequenced</GlossaryLink> the first
          set of rappels.
        </p>
        <p>
          Of the first group of rappels, the last rap is a{' '}
          <GlossaryLink id="multi-stage-rappel">two stage rappel</GlossaryLink>{' '}
          with a relatively tough <GlossaryLink>pull</GlossaryLink>. Corey was
          last and went <GlossaryLink>double strand</GlossaryLink>.
          Unfortunately the rope we had wasn't quite long enough to reach
          doubled over. This meant that he would have to{' '}
          <GlossaryLink>pass a knot</GlossaryLink> on one of the strands. He did
          it expertly, the rope pulled, and we continued on.
        </p>
        <Image
          image={data._DSF8489}
          caption="Second part of the two stage rappel"
        />
        <Image
          image={data._DSF8493}
          caption="We found a really big log in the canyon"
        />
        <Image
          image={data._DSF8504}
          caption={
            <>
              Zion rappels are often{' '}
              <GlossaryLink id="down-climb">down climbable</GlossaryLink>
            </>
          }
        />
        <Image
          image={data._DSF8506}
          caption="Rappelling down an angled log to avoid a smelly pool"
        />
        <p>
          We stopped at the confluence with Orderville and had lunch. While
          there, we heard what sounded like a group of drones flying. Turns out
          that wasn't too far from the truth. About 100' up above us there was a
          cloud of bees moving down the canyon.
        </p>
        <Image image={data._DSF8508} />
        <Image image={data._DSF8519} />
        <p>
          I love Orderville! It's such a beautiful hike. Combining it with
          Engelstead makes it a high quality day in Zion. I remember the first
          time I was in Orderville, looking up and losing my sense of direction.
          It looked to me like the canyon walls were reaching over us like a
          1000' high tidal wave.
        </p>
        <Image image={data._DSF8521_panorama} />
        <Image image={data._DSF8529_panorama} />
        <Image image={data._DSF8533_panorama} />
        <p>
          The giant <GlossaryLink>chockstone</GlossaryLink> with the trees
          living on it marks the point where I often start seeing
          narrows-bottom-up hikers. It also is where I put my camera away since
          it can't get wet.
        </p>
        <Image image={data._DSF8543} />
        <p>
          {' '}
          Once we start seeing the hikers, the priority shifts from enjoying the
          scenery and solitude to looking cool while quickly walking through the
          narrows without the use of a ZAC hiking stick.
        </p>
        <AllImagesLink id="2018-09-21-engelstead" />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-09-21-engelstead')(
  Blog_2018_09_21_Engelstead
);

export const query = graphql`
  query {
    _DSF8472: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8472.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8476: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8476.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8489: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8489.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8493: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8493.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8504: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8504.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8506: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8506.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8508: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8508.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8519: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8519.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8521_panorama: file(
      relativePath: {
        eq: "images/blog/2018-09-21-engelstead/_DSF8521_panorama.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8529_panorama: file(
      relativePath: {
        eq: "images/blog/2018-09-21-engelstead/_DSF8529_panorama.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8533_panorama: file(
      relativePath: {
        eq: "images/blog/2018-09-21-engelstead/_DSF8533_panorama.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8543: file(
      relativePath: { eq: "images/blog/2018-09-21-engelstead/_DSF8543.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
